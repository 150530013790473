
/* ==========================================================================
  hero animation
========================================================================== */
const $hero_title = $('.p-top-hero__title');
$win.on('load',function(){
	$hero_title.addClass('is-active');
});

/* ==========================================================================
  news
========================================================================== */
// news
function wpNews() {
  var wp_url = './wp-json/wp/v2/news/?_embed';
  var wp_nothumbnail = './assets/img/news/dummy.png';
  $.ajax({
    type: 'GET',
    url: wp_url,
    dataType: 'json'
  }).done(function(json) {
    var wp_html = '';
    //記事の件数分イテレートする
    $.each(json, function(i, item) {
      //ブログのタイトル
      var wp_title = item.title.rendered;
      //ブログのURL
      var wp_link = item.link;

      // li以下
      wp_html += '<li class="p-top-news__list"><a href="' + wp_link + '">';
      wp_html += wp_title;
      wp_html += '</a></li>';
    });
    //整形した記事の情報をページに追加する
    $('.js-newslist').append(wp_html)
  }).fail(function(json) {
    console.error('記事取得に失敗しました。')
  });
}

wpNews();

/* ==========================================================================
  casestudy
========================================================================== */
new Swiper(".casestudy-slider", {
  spaceBetween: 50,
  navigation: {
    nextEl: '.casestudy-button-next',
    prevEl: '.casestudy-button-prev',
  },
  pagination: {
    el: ".casestudy-pagination", 
    type: "fraction", 
  },
  effect: "fade",
});
